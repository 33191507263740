import React from "react";

import styles from "./button.module.css";

const Button = ({ text, submit }) => (
  <button type={submit ? "submit" : "button"} className={styles.btn}>
    {text}
  </button>
);

export default Button;
