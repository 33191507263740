import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
     

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}></button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}></nav>
    </div>
  </div>
);

export default Header;
